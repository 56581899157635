import { DataService } from '../shared/async-services/data.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { ListTable } from '@shared/models';
import { QueryParam } from '../features-modules/Agents-Management/param.modal';

@Injectable({
  providedIn: 'root',
})
export class AgentsManagementService {
  encryptSecretKey = 'letalliance_1234';
  AesKey = "HR$2pIjHR$2pIj12";
  AesIV = "HR$2pIjHR$2pIj12";
  public addOnCRMBasePath = "CRM/";
  public addOnOperationBasePath = "operation/";
  public documentBasePath = 'Document/';
  public addOnThirdpartyBasePath = 'thirdpartyapi/';
  public addOnFinancePath = "finance/";
  constructor(
    protected httpClient: HttpClient,
    protected dataService: DataService
  ) { }
  public encryptedParam: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  updateQueryParam(data): void {
    this.encryptedParam.next(data);
  }
  public GetAgentType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentType');
  }
  public GetBusinessType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'BusinessType');
  }
  public GetInsuranceStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetAgentInsuranceStatus');
  }

  public GetBDM(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'BDM');
  }
  public GetListAgentCategory(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'ListAgentCategory');
  }

  public GetCompany(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Company');
  }
  public GetReferencingSystem(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'ReferencingSystem');
  }
  // public GetServiceType(): Observable<Array<ListTable>> {
  //   return this.dataService.get(this.addOnCRMBasePath + 'ServiceType');
  // }
  public GetLstServiceType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetLstServiceType');
  }
  public GetServiceType(referencingCompany: any): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'ServiceType/GetServiceType/' + referencingCompany);
  }
  public GetAgentDocumentAccessType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetAgentDocumentAccessType');
  }
  public TeamContactList(): Observable<any> {

    return this.dataService.get(this.addOnCRMBasePath + "TeamContact");

  }
  public servicetypeList(): Observable<any> {

    return this.dataService.get(this.addOnCRMBasePath + "ServiceType");

  }

  public deleteContactType(serviceTypeId: number) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'TeamContact/' + serviceTypeId
    );
  }


  public deleteServiceType(serviceTypeId: number) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'ServiceType/' + serviceTypeId
    );
  }


  public getAgentDocumentEnum(): Observable<Array<ListTable>> {

    return this.dataService.get(this.addOnCRMBasePath + 'GetAgentDocument');

  }


  public addAgentDocument(objSave: any): Observable<any> {

    return this.dataService.post(this.addOnCRMBasePath + 'AgentDocument/AddAgentDocument', objSave);

  }

  public deleteAgentdocument(agentDocumentId: number) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'AgentDocument/DeleteAgentDocument/' + agentDocumentId
    );
  }

  // public deleteSignUpDocument(agentDocumentId: number) {
  //   return this.dataService.delete(this.addOnCRMBasePath +
  //     'SignUp/DeleteSignUpDocument/' + agentDocumentId
  //   );
  // }
  public getAgentDocumentList(): Observable<any> {

    return this.dataService.get(

      this.addOnCRMBasePath + "AgentDocument/GetAgentDocumentList");

  }

  public GetAgentDocumentAccessList(agentDocumentId: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentDocument/GetAgentDocumentAccessList/' + agentDocumentId);
  }

  public AddUpdateTeamContact(objSave: any): Observable<any> {

    return this.dataService.post(this.addOnCRMBasePath + 'teamContact', objSave);

  }

  public AddUpdateServiceType(objSave: any): Observable<any> {

    return this.dataService.post(this.addOnCRMBasePath + 'ServiceType', objSave);

  }

  public SaveLSLBranch(objSave: any): Observable<any> {

    return this.dataService.post(this.addOnCRMBasePath + 'AgentSpecialAttr/SaveLSLBranch', objSave);

  }


  public SaveReferencingReport(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'ReferencingConfiguration/group/SaveReferencingReport', objSave);
  }

  public GetReferencingReport(agentGroupId: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'ReferencingConfiguration/GetReferencingReport/' + agentGroupId);
  }


  public GetLSLBranch(agentId: any): Observable<any> {

    return this.dataService.get(this.addOnCRMBasePath + 'AgentSpecialAttr/GetLSLBranch/' + agentId);

  }

  public GetBankDetailType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'BankDetailType');
  }

  public GetFinancePaymentMethod(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'FinancePaymentMethod');
  }

  public GetFinancePaymentOption(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'FinancePaymentOption');
  }

  public GetTypeOfGroup(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'TypeOfGroup');
  }

  public GetComapnyType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'CompanyType');
  }
  public GetParentGroup(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup/GetParentGroup');
  }

  public GetChecksResultStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'ChecksResultStatus');
  }
  public GetCreditCheckStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'CreditCheckStatus');
  }
  public GetChecksInsuranceResultStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'ChecksInsuranceResultStatus');
  }
  public GetAgentUserType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentUserType');
  }
  public GetAgentBOUserType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentBOUserType');
  }
  public GetAgentTitle(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'getAgentTitle');
  }
  public GetContactTyp(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetContactType');
  }
  public GetReferencingChargePaidBy(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'ReferencingChargePaidBy');
  }

  public GetRGSystem(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'RGSystem');
  }
  public GetEnumAgentType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetEnumAgentType');
  }

  public GetAPIsource(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'APISource');
  }

  public GetAgentUserStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentUserStatus');
  }

  public GetUserStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'UserStatus');
  }

  public GetAgentListTableByGroupId(agenGrouptId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + `AgentGroup/GetAgentListTableByGroupId/${agenGrouptId}`);
  }

  public CheckIfUserNameAlreadyExists(objSendData: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath +
      'AgentUser/CheckIfUserNameAlreadyExists',
      objSendData
    );
  }

  public CheckIfEmailIdAlreadyExists(objSendData: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath +
      'AgentUser/CheckIfEmailIdAlreadyExists',
      objSendData
    );
  }

  public GetSignUpDocumentByAgent(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetSignUpDocumentByAgent');
  }

  public GetSignUpDocumentByGroup(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetSignUpDocumentByGroup');
  }

  public GetSignUpDocumentByPartner(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetSignUpDocumentByPartner');
  }

  public GetDocumentCompletedType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetDocumentCompletedType');
  }

  public GetEmployee(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'getEmployee');
  }

  public getEmployeeDetais(userId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + "Users/GetEmployeeDetail"
    );
  }

  public GetAllEmployee(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Employee');
  }

  public GetAllFinancePaymentMethod(agentId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentFinanceInfo/GetAllFinancePaymentMethod?agentId=' + agentId);
  }

  public GetUsers(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'users');
  }

  public GetDepartMent(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'getDepartMent');
  }

  public GetRenewalOptions(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'getRenewalOptions');
  }

  public GetLstClaimsPayment(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'getLstClaimsPayment');
  }

  public GetInsuranceAgentStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetInsuranceAgetStatus');
  }
  public InsertUpdatePartner(objSave: any): Observable<any> {
    if (objSave.partnerId !== null && objSave.partnerId > 0) {
      return this.dataService.put(this.addOnCRMBasePath + 'Partner/' + objSave.partnerId, objSave);
    } else {
      return this.dataService.post(this.addOnCRMBasePath + 'Partner', objSave);
    }
  }
  public GetComapnyTypedata(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'agentGroup/GetComapnyTypedata/' + id);
  }



  getGroups(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup');
  }
  getAgentCode(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GenerateCode/AGENT/101');
  }
  getPartnerCode(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'partner/getCode');
  }
  getGroupCode(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'agentGroup/getCode');
  }
  getAgentStatus(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentStatus');
  }
  getAgentList(serachmodel: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'GetAgentList', serachmodel);
  }
  getPartners(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Partner');
  }
  public InsertUpdatAgent(objSave: any): Observable<any> {
    if (objSave.agentId !== null && objSave.agentId > 0)
      return this.dataService.put(this.addOnCRMBasePath + 'Agent/' + objSave.agentId, objSave);
    else
      return this.dataService.post(this.addOnCRMBasePath + 'Agent', objSave);

  }
  public InsertUpdatAgentProprietorManaging(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'Agent/SaveProprierorManaging', objSave);

  }

  public setAgentGroupLive(id: number): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + 'agentGroup/setLive/' + id, null);
  }

  public InsertUpdatGroup(objSave: any): Observable<any> {
    if (objSave.agentGroupId !== null && objSave.agentGroupId > 0) {
      return this.dataService.put(this.addOnCRMBasePath +
        'AgentGroup/' + objSave.agentGroupId,
        objSave
      );
    } else {
      return this.dataService.post(this.addOnCRMBasePath + 'AgentGroup', objSave);
    }
  }


  public setPartnerLive(id: number): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + 'Partner/setLive/' + id, null);
  }

  public GetPartnerInfo(id: number): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Partner/' + id);
  }
  public GetAgentInfo(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent', id);
  }

  public GetAgentHeader(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/Header/' + id);
  }

  public GetAgentGroupHeader(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup/Header/' + id);
  }


  public GetEmployeeType(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Employee/GetEmployeeType/' + id);
  }

  public GetPartnerHeader(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Partner/Header/' + id);
  }

  public GetAgentGroupInfo(id: number): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup/' + id);
  }

  public getTeams(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'TeamGroup/teams');
  }

  public getreferenceids(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/referenceids/' + id);
  }

  public getreferenceidsforGroup(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup/referenceids/' + id);
  }

  public getPrimaryTeam(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'PrimaryTeam');
  }
  public getSecondaryTeam(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'SecondaryTeam');
  }
  public GetReferencingConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'ReferencingConfiguration/Agent/' + id);
  }
  public GetRentGuaranteeConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentRentGuaranteeConfiguration/Agent/' + id);
  }

  public GetGroupRentGuaranteeConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentRentGuaranteeConfiguration/Group/' + id);
  }
  public GetPartnerRentGuaranteeConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentRentGuaranteeConfiguration/Partner/' + id
    );
  }

  public GetAgentGroupReferencingConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'ReferencingConfiguration/Group/' + id);
  }


  public GetAgentGroupEmailUpdate(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup/GetAgentGroupEmailUpdate/' + id);
  }

  public GetPartnerReferencingConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'ReferencingConfiguration/Partner/' + id);
  }
  public GetInsuranceConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'InsuranceConfiguration/' + id);
  }
  public GetAgentGroupInsuranceConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      `InsuranceConfiguration/GetAgentGroupInsuranceConfig/${id}`
    );
  }
  public GetAgentPartnerInsuranceConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      `InsuranceConfiguration/GetAgentPartnerInsuranceConfig/${id}`
    );
  }
  public GetAgentPartnerNilDepositConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      `AgentNilDepositConfiguration/GetAgentPartnerNilDepositConfig/${id}`
    );
  }
  public SetInsuranceConfig(id: number, objsave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + `InsuranceConfiguration/${id}`, objsave);
  }
  public SetAgentGroupInsuranceConfig(
    id: number,
    objsave: any
  ): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      `InsuranceConfiguration/SetAgentGroupInsuranceConfig/${id}`,
      objsave
    );
  }
  public SetAgentPartnerInsuranceConfig(
    id: number,
    objsave: any
  ): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      `InsuranceConfiguration/SetAgentPartnerInsuranceConfig/${id}`,
      objsave
    );
  }
  public SetAgentPartnerNilDepositConfig(
    id: number,
    objsave: any
  ): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      `AgentNilDepositConfiguration/SetAgentPartnerNilDepositConfig/${id}`,
      objsave
    );
  }

  public SaveReferencingConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'ReferencingConfiguration/' + objSave.agentId,
      objSave
    );
  }

  public SaveRGGroupRates(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentRGConfiguration/SaveGroupRates',
      objSave
    );
  }

  public SaveRGRates(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentRGConfiguration/SaveRates',
      objSave
    );
  }

  public SaveGroupPageConfiguration(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentRGConfiguration/SaveGroupPageConfiguration',
      objSave
    );
  }

  public SavePageConfiguration(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentRGConfiguration/SavePageConfiguration',
      objSave
    );
  }


  public SaveAgentRGConfiguration(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentRGConfiguration', objSave);
  }

  public SaveAgentRgProductSettings(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentRGConfiguration/SaveAgentRgProductSettings', objSave);
  }
  public SaveOverrideProductSettings(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentRGConfiguration/SaveOverrideProductSettings', objSave);
  }
  public SaveAgentClaimsConfiguration(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentClaimsConfiguration/AddAgentClaimsConfiguration', objSave);
  }
  public GetAgentClaimsConfiguration(agentId: number): Observable<any> {
    return this.dataService.get(`${this.addOnCRMBasePath}AgentClaimsConfiguration/GetAgentClaimsConfiguration/${agentId}`);
  }
  public GetAgentRGConfiguration(agentId: number): Observable<any> {
    return this.dataService.get(`${this.addOnCRMBasePath}AgentRGConfiguration/${agentId}`);
  }

  public GetAgentRGGroupConfiguration(agentGroupId: number): Observable<any> {
    return this.dataService.get(`${this.addOnCRMBasePath}AgentRGConfiguration/Group/${agentGroupId}`);
  }

  public SaveGroupReferencingConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'ReferencingConfiguration/group/' + objSave.agentGroupId,
      objSave
    );
  }

  public SavePartnerReferencingConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'ReferencingConfiguration/partner/' + objSave.partnerId,
      objSave
    );
  }

  public SaveRentGuaranteeConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentRentGuaranteeConfiguration/' + objSave.agentId,
      objSave
    );
  }

  public SaveGroupRentGuaranteeConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentRentGuaranteeConfiguration/group/' + objSave.agentGroupId,
      objSave
    );
  }
  public SavePartnerRentGuaranteeConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentRentGuaranteeConfiguration/partner/' + objSave.partnerId,
      objSave
    );
  }


  public getGlobalSearchResult(searchParams: string): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/search/Paged?' + searchParams);
  }

  public getGlobalSearchAnnouncementResult(searchParams: string): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/searchannouncement/Paged?' + searchParams);
  }

  public getSearchDetails(type: number, id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/search/detail/' + type + '/' + id);
  }

  public GetAgentNilDepositConfiguration(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentNilDepositConfiguration/' + id);
  }

  public GetGroupNilDepositConfiguration(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentNilDepositConfiguration/group/' + id);
  }

  public SaveNilDiposit(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentNilDepositConfiguration/' + objSave.agentId,
      objSave
    );
  }

  public SaveGroupNilDiposit(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentNilDepositConfiguration/group/' + objSave.agentGroupId,
      objSave
    );
  }

  public getSearchAgentByGroupPartner(
    type: number,
    id: number
  ): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      'Agent/search/SearchAgentByGroupPartner/' + type + '/' + id
    );
  }

  public CreateUpdateAgentUser(objSave: any): Observable<any> {
    return this.dataService.post('UserManagement/CreateUpdateAgentUser', objSave);
  }

  public AddUpdateEmployeeDetail(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'Employee/AddUpdateEmployeeDetail', objSave);
  }
  public updateEmployee(userDetails: any, id: number): Observable<any> {
    return this.dataService
      .put(this.addOnOperationBasePath + "Users/" + id, userDetails)
  }
  public TransferAgentUser(
    agentUserId: number,
    agentId: number
  ): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath +
      'AgentUser/TransferUser/' + agentUserId + '/' + agentId,
      null
    );
  }

  public ChangeUserStatus(objSave: any): Observable<any> {
    return this.dataService.put('UserManagement/ChangeAgentUserStatus', objSave);
  }

  public ChangeEmployeeStatus(objSave: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + 'Employee/ChangeEmployeeStatus/' + objSave.employeeId + '/' + objSave.userStatus, {});
  }

  public TransferGroupUser(
    agentUserId: number,
    agentGroupId: number
  ): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath +
      'AgentUser/TransferGroupUser/' + agentUserId + '/' + agentGroupId,
      null
    );
  }

  public GetGroupUsers(agentGroupId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup/GetGroupUsers', agentGroupId);
  }

  public GetCommunicationsBysystem(systemId: number, agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/GetAgentCommunicationConfigurationForSystem/' +
      agentid +
      '/' +
      systemId
    );
  }

  public GetAgentContactHistory(agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentContactHistory/' + agentid);
  }
  public GetActivityLogTab(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetActivityLogTab');
  }
  public DeleteAgentContactHistory(contactHistoryId: number) {
    return this.dataService.delete(this.addOnCRMBasePath + 'AgentContactHistory/DeleteAgentContactHistory/' + contactHistoryId);

  }
  public GetGroupContactHistory(groupId: number) {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentContactHistory/GetAgentGroupContactHistory/' + groupId
    );
  }

  public GetAgentDocuments(agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentDocumentStore/' + agentid);
  }
  public GetAgentDocumentsSignUp(agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentDocumentStoreSignUp/' + agentid);
  }
  public GetInvoiceDocuments(data: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentDocumentStore/GetInvoiceDocuments', data);
  }
  public GetAgentSelfBilling(agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSelfBilling/' + agentid);
  }
  public GetGroupSelfBillingService(agentGroupId: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSelfBilling/GetGroupSelfBillingService/' + agentGroupId);
  }
  public GetGroupAllSelfBillingService(agentGroupId: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSelfBilling/GetGroupAllSelfBillingService/' + agentGroupId);
  }
  public GetSelfBillingService(agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSelfBilling/GetSelfBillingService/' + agentid);
  }

  public SendSelfBillingResetLink(agentSelfBillingId: number, isReset: boolean) {
    return this.dataService.put(this.addOnCRMBasePath + `AgentSelfBilling/sendSelfBillingReset/${agentSelfBillingId}/${isReset}`, null);
  }

  public noWhitespaceValidator(control: FormControl) {
    const validVal =
      (control.value || "").trim().length == (control.value || "").length;
    if (validVal) {
      return null;
    }
    return { whitespace: true };
  }

  public GetGroupDocuments(groupId: number) {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentDocumentStore/GetGroupDocuments/' + groupId
    );
  }

  public GetGroupCommunicationsBysystem(
    systemId: number,
    agentGroupid: number
  ) {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/GetAgentGroupCommunicationConfigurationForSystem/' +
      agentGroupid +
      '/' +
      systemId
    );
  }

  public GetSystemNotificationsById(systemId: number) {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/GetEmailNotificationsTypeForSystem/' +
      systemId
    );
  }
  public DeleteUser(
    agentUserId: number
  ) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'AgentUser/' + agentUserId
    );
  }

  public DeleteEmployee(
    employeeId: number
  ) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'Employee/' + employeeId
    );
  }

  public GetCommunactionNotifications(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetCommunactionNotifications');
  }

  public GetAllAgentUsers(): Observable<Array<any>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetAllAgentUsers');
  }
  public GetLast3DaysDeactiveUsers() {
    return this.dataService.get(
      'UserManagement/GetLast3DaysDeactiveUsers/'
    );
  }
  public SaveAgentUserPage(agentId: number, data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentUser/SaveAgentUserPage/' + agentId,
      data
    );
  }

  public SaveSelfBillingById(data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentSelfBilling/Save',
      data
    );
  }

  public SaveCreditLog(agentId: number, data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentCreditLog/SaveCreditLog/' + agentId,
      data
    );
  }
  public UpdateCreditScore(agentId: number, data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentCreditLog/UpdateCreditScore/' + agentId,
      data
    );
  }


  public AddCreditLogActivityNote(data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentCreditLog/AddCreditLogActivityNote',
      data
    );
  }
  public GetCreditLog(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentCreditLog/' + id);
  }

  public DeleteAgentCreditLogById(id: number) {
    return this.dataService.delete(this.addOnCRMBasePath + 'AgentCreditLog/' + id
    );
  }
  public GetAgentCreditLogById(agentCreditLogId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentCreditLog/GetAgentCreditLogById/' + agentCreditLogId);
  }
  public GetAgentCreditbalance(agentId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentCreditLog/GetAgentCreditbalance/' + agentId);
  }
  public GetAgentCreditLogActivityLogById(agentId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentCreditLogActivityLog/' + agentId);
  }

  public SaveAgentGroupUserPage(
    agentGroupId: number,
    data: any
  ): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentUser/SaveAgentGroupUserPage/' + agentGroupId,
      data
    );
  }

  public GetAgentUser(agentUserId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentUser/GetAgentUser/' + agentUserId);
  }

  public GetAgentUserManagement(agentUserId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Employee/GetAgentUserManagement/' + agentUserId);
    // return this.dataService.get(this.addOnOperationBasePath + 'Users/' + agentUserId);
  }

  public GetUserDetails(agentUserId: number): Observable<any> {
    // return this.dataService.get(this.addOnCRMBasePath + 'Employee/GetAgentUserManagement/' + agentUserId);
    return this.dataService.get(this.addOnOperationBasePath + 'Users/' + agentUserId);
  }
  public GetActiveEmployee(): Observable<any> {
    // return this.dataService.get(this.addOnCRMBasePath + 'Employee/GetAgentUserManagement/' + agentUserId);
    return this.dataService.get(this.addOnOperationBasePath + 'Employee/GetActiveEmployee?systemId=256');
  }
  public GetEmployeeById(agentUserId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Employee/GetEmployeeById/' + agentUserId);
  }


  public GetAgentList(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetAgentName');
  }

  public GetAgentGroupList(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentGroup/GetAgentGroupList');
  }

  public GetAllAgentGroupUsers(agentGroupId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentUser/GetAllAgentGroupUsers',
      agentGroupId
    );
  }

  public SaveSystemNotificationsById(data: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentCommunicationConfiguration', data);
  }

  public SaveAgentDocument(agentId: number, data: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentDocumentStore/' + agentId, data);
  }
  public SaveAgentDocumentSignUp(agentId: number, data: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentDocumentStoreSignUp/' + agentId, data);
  }


  public SaveGroupDocument(groupId: number, data: any) {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentDocumentStore/AgentGroupDocument/' + groupId,
      data
    );
  }

  public SaveSignUpDocuments(useConfig: number, id: number, data: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'SignUp/' + useConfig + '/' + id, data);
  }

  public SetAgentCommunicationFlags(data: any) {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/SetAgentCommunicationFlags',
      data
    );
  }

  public SetAgentGroupCommunicationFlags(data: any) {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/SetAgentGroupCommunicationFlags',
      data
    );
  }

  public SetAgentDocumentFlags(data: any) {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentDocumentStore/SetAgentDocumentFlags',
      data
    );
  }

  public SetGroupDocumentFlags(data: any) {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentDocumentStore/SetAgentGroupDocumentFlags',
      data
    );
  }

  public DeleteSystemNotificationsById(
    AgentCommunicationConfigurationId: number
  ) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/' + AgentCommunicationConfigurationId
    );
  }

  public checkIfFinancialAlreadyExists(obj: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/checkIfFinancialAlreadyExists',
      obj
    );
  }
  public GetAgentCommunicationConfiguration(systemId: number, agentid: number, agentCommunicationConfigurationId: number) {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/GetAgentCommunicationConfiguration/' + agentid + '/' + systemId + '/' + agentCommunicationConfigurationId);
  }

  public GetGroupCommunicationConfiguration(systemId: number, agentGroupId: number, agentCommunicationConfigurationId: number) {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentCommunicationConfiguration/GetGroupCommunicationConfiguration/' + agentGroupId + '/' + systemId + '/' + agentCommunicationConfigurationId);
  }

  public DeleteAgentdocumentById(AgentDocumentStoreId: number) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'AgentDocumentStore/' + AgentDocumentStoreId
    );
  }


  public DeleteFinancePaymentMethodById(AgentDocumentStoreId: number) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'AgentFinanceInfo/deletepayment/' + AgentDocumentStoreId
    );
  }

  public DeleteFinancePaymentOptionById(AgentDocumentStoreId: number) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'AgentFinanceInfo/deletepaymentOption/' + AgentDocumentStoreId
    );
  }


  public DeleteSignUpDocument(AgentDocumentStoreId: number) {
    return this.dataService.delete(this.addOnCRMBasePath +
      'SignUp/DeleteSignUpDocument/' + AgentDocumentStoreId
    );
  }

  public ApproveDocument(AgentDocumentStoreId: number) {
    return this.dataService.put(this.addOnCRMBasePath +
      'SignUp/ApproveDocument/' + AgentDocumentStoreId, null
    );
  }



  public ShowHideInVault(AgentDocumentStoreId: number, showHide: boolean) {
    return this.dataService.put(this.addOnCRMBasePath +
      'SignUp/ShowHideInVault/' + AgentDocumentStoreId + '/' + showHide, null
    );
  }

  public UploadAgentStoreDocument(fileData: FormData) {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentDocumentStore/SaveDocument',
      fileData
    );
  }
  public downloadDocument(docPath: string): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      'AgentDocumentStore/Download?path=' + docPath
    );
  }
  public SaveAgentContactHistory(data: any, agentId: number) {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentContactHistory/' + agentId, data);
  }

  public SaveGroupContactHistory(data: any, groupId: number) {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentContactHistory/AddUpdateAgentContactHistory/' + groupId,
      data
    );
  }

  public GetSignUpForAgent(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'SignUp/Agent/' + id);
  }

  public setAgentLive(id: number): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + 'Agent/setLive/' + id, null);
  }

  public GetSignUpForAgentGroup(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'SignUp/Group/' + id);
  }

  public GetSignUpForPartner(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'SignUp/Partner/' + id);
  }

  public GetAgentApiAccess(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + `AgentApiSetup/${id}`);
  }

  public addApiKey(objsave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + `AgentApiSetup`, objsave);
  }


  public updateintegrationKey(objsave: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + `AgentApiSetup`, objsave);
  }
  public deleteApiKey(agenntId: number, systemId: number): Observable<any> {
    return this.dataService.delete(this.addOnCRMBasePath + `AgentApiSetup/${agenntId}/${systemId}`);
  }

  public GetAgentDashboardData(agenntId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + `AgentDashboard/${agenntId}?noloaded=true`);
  }

  public GetAgentGroupDashboardData(agenntGroupId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath +
      `AgentDashboard/GetAgentGroupDashboardData/${agenntGroupId}`
    );
  }

  public SendTeamMail(objSendData: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'SignUp/TeamMail', objSendData);
  }

  public SendActuresMail(objSendData: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'SignUp/ActuresMail', objSendData);
  }

  public SendPasswordResetLink(agentUserId: number, isReset: boolean) {
    return this.dataService.put(this.addOnCRMBasePath + `AgentUser/sendPasswordReset/${agentUserId}/${isReset}`, null);
  }

  public SendPasswordResetLinkToEmployee(employeeId: number, isReset: boolean) {
    return this.dataService.put(this.addOnCRMBasePath + `Employee/sendPasswordResetToEmployee/${employeeId}/${isReset}`, null);
  }
  public SendPasswordResetActivityLog(employeeId: number, displayName: any) {
    return this.dataService.post(this.addOnCRMBasePath + `Employee/SendPasswordResetActivityLog/${employeeId}/${displayName}`, null);
  }
  public resetPassword(userDetails: any): Observable<any> {
    return this.dataService.post(this.addOnOperationBasePath + "Users/SendResetLink", userDetails)
  }
  public downloadActivity(userDetails: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + "SignUp/DownloadDocumentActivity", userDetails)
  }
  public GetActivityAgentWise(agentId: number): Observable<any> {
    return this.dataService.get(`Activity/GetAllActivityByKeyNModule/${agentId}/Agent`);
  }


  public GetActivitycontectAgentWise(agentId: number, module: string, submodule: string): Observable<any> {
    if (submodule != null && submodule != "")
      return this.dataService.get(`Activity/GetAllActivityByKeySubModule/${agentId}/${module}/${submodule}`);
    else
      return this.dataService.get(`Activity/GetAllActivityByKeyNModule/${agentId}/${module}`);
  }



  public GetActivityAgentTabWise(agentId: number, module: string, submodule: string): Observable<any> {
    if (submodule != null && submodule != "")
      return this.dataService.get(`Activity/GetAllActivityByKeySubModule/${agentId}/${module}/${submodule}`);
    else
      return this.dataService.get(`Activity/GetAllActivityByKeyNModule/${agentId}/${module}`);
  }

  public GetActivitybySubModuleNModule(module: string, submodule: string): Observable<any> {
    return this.dataService.get(`Activity/GetActivitybySubModuleNModule/${module}/${submodule}`);
  }

  public GetActivitybySubModuleNKeyPaged(objReq: any): Observable<any> {
    return this.dataService.post(
      "Activity/GetActivitybySubModuleNKeyPaged", objReq);
  }

  public GetActivityAgentGroupWise(agenGrouptId: number): Observable<any> {
    return this.dataService.get(`Activity/GetAllActivityByKeyNModule/${agenGrouptId}/AgentGroup`);
  }

  public GetActivityPartnerWise(partnerId: number): Observable<any> {
    return this.dataService.get(`Activity/GetAllActivityByKeyNModule/${partnerId}/Partner`);
  }

  public GetAgentMediaDetail(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentMediaDetail/GetAgentMediaDetail/' + id);
  }
  public GetAgentGroupMediaDetails(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentMediaDetail/GetAgentGroupMediaDetails/' + id);
  }
  public GetMediaUtilitiesCodeDetail(id: number, mediaId: number, companyId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentMediaDetail/GetMediaUtilitiesCodeDetail/' + mediaId + '/' + id + '/' + companyId);
  }

  public GetGroupMediaUtilitiesCodeDetail(id: number, mediaId: number, companyId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentMediaDetail/GetGroupMediaUtilitiesCodeDetail/' + mediaId + '/' + id + '/' + companyId);
  }

  public GetMediaUtilitiesCodeForAllProduct(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentMediaDetail/GetMediaUtilitiesCodeForAllProduct/' + id);
  }

  public getagentFinanceInfo(agnetId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentFinanceInfo/' + agnetId);
  }

  public sendDirectUpdateBank(agentFinanceInfoId: number): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + `AgentFinanceInfo/SendDirectUpdateBank/` + agentFinanceInfoId, null);
  }

  public deleteagentFinanceInfo(agentFinanceInfoId: number): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + `AgentFinanceInfo/delete/${agentFinanceInfoId}`, null);
  }

  // public SaveMediaUtilities(objSave: any): Observable<any> {
  //   return this.dataService.post(this.addOnCRMBasePath +
  //     'AgentMediaDetail/' + objSave.agentId,
  //     objSave
  //   );
  // }
  public SaveMediaUtilities(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentMediaDetail', objSave);
  }

  public GetDisableInsPermission(agentId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSpecialAttr/GetDisableInsPermission/' + agentId);
  }
  public GetAgentAttributes(agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSpecialAttr/' + agentid);
  }


  public GetR3Automation(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetR3Automation');
  }

  public InsertUpdateAgentBankDetails(objsave: any): Observable<any> {
    if (objsave.agentFinanceInfoId > 0) {
      return this.dataService.put(this.addOnCRMBasePath + `AgentFinanceInfo/` + objsave.agentId, objsave);
    } else {
      return this.dataService.post(this.addOnCRMBasePath + `AgentFinanceInfo`, objsave);
    }

  }
  public InsertUpdateFinancePaymentMethod(objsave: any): Observable<any> {

    return this.dataService.put(this.addOnCRMBasePath + `AgentFinanceInfo/AddUpdateFinancePaymentMethod`, objsave);
  }

  public InsertUpdateFinancePaymentOption(objsave: any): Observable<any> {

    return this.dataService.put(this.addOnCRMBasePath + `AgentFinanceInfo/AddUpdateFinancePaymentOption`, objsave);
  }

  public UpdateAgentGroupEmail(objsave: any, agentGroupId: number): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + `AgentGroup/UpdateAgentGroupEmail/` + agentGroupId, objsave);

  }

  public InsertUpdateAgentAttributes(objSave: any): Observable<any> {
    if (objSave.mode == 'edit') {
      return this.dataService.put(this.addOnCRMBasePath + 'AgentSpecialAttr/' + objSave.agentSpecialAttrId, objSave);
    } else {
      return this.dataService.post(this.addOnCRMBasePath + 'AgentSpecialAttr', objSave);
    }
  }

  public InsertUpdateAgentPremierAttributes(objSave: any): Observable<any> {
    if (objSave.modePremier == 'edit') {
      return this.dataService.put(this.addOnCRMBasePath + 'AgentSpecialAttrAppian/' + objSave.agentSpecialAttrAppianId, objSave);
    } else {
      return this.dataService.post(this.addOnCRMBasePath + 'AgentSpecialAttrAppian', objSave);
    }
  }

  public GetAgentPermierAttributes(agentid: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSpecialAttrAppian/' + agentid);
  }

  public SaveAdverseCreditConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentSpecialAttr/SaveAdverseCreditConfig/', objSave);
  }

  public GetAdverseCreditConfig(agentId: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSpecialAttr/GetAdverseCreditConfig/' + agentId);
  }

  public ImportAgentConfiguration(objSave: any): Observable<any> {
    return this.dataService.post('AppianData/ImportAgentConfiguration', objSave);
  }

  public AddActivityNote(data: any) {
    return this.dataService.post(
      this.addOnCRMBasePath + 'AgentSpecialAttr/AddActivityNote',
      data
    );
  }


  public AddActivityNoteActuris(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnThirdpartyBasePath + 'Acturis/AddAgentContactActivity', objSave);
  }
  public UploadDocumentToActivity(fileData: FormData) {
    return this.dataService.post(this.addOnThirdpartyBasePath +
      'Acturis/UploadDocumentToActivity',
      fileData
    );
  }

  public GetAgentListByGroupId(agenGrouptId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + `AgentGroup/GetAgentListByGroupId/${agenGrouptId}`);
  }

  public GetAllUsersByAgentId(agentUserId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + `AgentUser/GetUserAgentInfo/${agentUserId}`);
  }
  public UserRequestContact(objSave: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath + 'AgentUser/UserRequestContact/' + objSave.agentUserId, objSave);
  }
  public clearGridDataSource(gridInstance: GridComponent) {
    gridInstance.data = [];
  }
  public GetAllLstAgentCategory(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'LstAgentCategory/GetAllLstAgentCategory');
  }

  public SaveLstAgentCategory(data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'LstAgentCategory/SaveLstAgentCategory',
      data
    );
  }
  public GetLstAgentCategory(lstAgentCategoryId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'LstAgentCategory/' + lstAgentCategoryId);
  }

  public GetAllLstAgentBDM(): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'LstAgentBdm/GetAllLstAgentBDM');
  }

  public GetSystemConfiguration(ConfigurationValue: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'GetSystemConfig/' + ConfigurationValue);
  }

  public SaveLstBDM(data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'LstAgentBdm/SaveLstAgentBDM', data);
  }

  public DeleteLstAgentBDMById(id: number) {
    return this.dataService.delete(this.addOnCRMBasePath + 'LstAgentBdm/' + id
    );
  }

  public DeleteLstAgentCategoryById(id: number) {
    return this.dataService.delete(this.addOnCRMBasePath + 'LstAgentCategory/' + id
    );
  }

  public checkIfCategoryAlreadyExists(objSendData: any): Observable<any> {
    return this.dataService.put(this.addOnCRMBasePath +
      'LstAgentCategory/checkIfCategoryAlreadyExists',
      objSendData
    );
  }

  public DeleteOperationUser(userId: number) {
    return this.dataService.delete(this.addOnOperationBasePath + 'Users/' + userId);
  }

  public AddAgentBussinessOwner(objSave: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'Agent/AddAgentBussinessOwner', objSave);
  }

  public AddAgentBussinessOwnerDetails(objSave: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'Agent/AddAgentBussinessOwnerDetails', objSave);
  }

  public DeleteAgentBussinessOwnerDetails(agentBussinessOwnerDetailsId: number) {
    return this.dataService.put(this.addOnCRMBasePath + 'Agent/DeleteAgentBussinessOwnerDetails/' + agentBussinessOwnerDetailsId, {});
  }
  public AddAgentBusinessOtherContact(objSave: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'Agent/AddAgentBusinessOtherContact', objSave);
  }


  public DeleteAgentBusinessOtherContact(agentBusinessOtherContactId: number) {
    return this.dataService.put(this.addOnCRMBasePath + 'Agent/DeleteAgentBusinessOtherContact/' + agentBusinessOtherContactId, {});
  }

  public SaveBusinessOtherContactDetails(objSave: any, agentId: any) {
    return this.dataService.post(this.addOnCRMBasePath + `Agent/SaveBusinessOtherContactDetails/${agentId}`, objSave);
  }
  // public SaveAgentFCA(objSave: any) {
  //   return this.dataService.post(this.addOnCRMBasePath + 'Agent/AddAgentFCA', objSave);
  // }

  public SaveIntegration(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentIntegration/SaveAgentIntegrationConfig', objSave);
  }

  public GetIntegrationById(agentId: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentIntegration/Agent/' + agentId);
  }

  public GetInvoiceDocumentConfig(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'InvoiceDocumentConfigType');
  }

  public encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  public getQueryParam(data): QueryParam {
    const decryptparams: QueryParam = this.decryptData(data);
    return decryptparams;
  }


  public encryptPayment(data): string {
    try {
      return CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(data),
        CryptoJS.enc.Utf8.parse(this.AesKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.AesIV),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  public decryptPayment(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(
        data,
        CryptoJS.enc.Utf8.parse(this.AesKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.AesIV),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      if (bytes.toString()) {
        return bytes.toString(CryptoJS.enc.Utf8);
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }


  public decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(
        data,
        CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (!control.valid) {
        console.log(field + ' Is Invalid');
      }
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public decryptAndParseQueryString(enceyptedData: string) {
    if (enceyptedData === undefined || enceyptedData === null) {
      return;
    }
    const decryptparams = this.decryptData(enceyptedData);
    let agentId;
    if (decryptparams.data !== undefined && decryptparams.data !== null) {
      agentId = parseInt(decryptparams.data);
    }
    return {
      agentId: agentId,
    };
  }
  public GetAgentDocumentType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'getAgentDocumentType');
  }
  public GetVerifyAgentStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'VerifyAgentStatus');
  }
  public UploadFileToS3(objFileInfo: any): Observable<any> {
    return this.dataService.post(
      this.documentBasePath + 'Document/UploadDocument',
      objFileInfo
    );

  }

  public UploadVideo(objFileInfo: any): Observable<any> {
    return this.dataService.post(
      this.documentBasePath + 'Document/UploadVideo',
      objFileInfo
    );

  }


  public GetAgentContactHistoryNotes(agentContactHistoryId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentContactHistory/getAgentContactHistoryNotes/' + agentContactHistoryId);
  }
  public SaveManualAddress(objAddress: any): Observable<any> {
    return this.dataService.post(
      'AddressData/ManualPropertyAddress',
      objAddress
    );
  }

  getCountries(): Observable<any> {
    return this.dataService
      .get(this.addOnCRMBasePath + 'countries');
  }

  public SaveSignup(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentSignUp/SaveSignup', objSave);
  }

  public GetAgentCode(agentCode: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetAgentCode/' + agentCode);
  }
  public GetAgentCodeSignUp(agentCode: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSignUp/GetAgentCode/' + agentCode);
  }
  public isKeyValidate(key: string): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSignUp/' +
      key
    );
  }
  public SaveDirectDebitById(agentId: number, data: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentDirectDebit/SaveAgentDirectDebit/' + agentId,
      data
    );
  }



  public GetAgentDirectDebit(agentId: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'NewAgentInfirmation/' + agentId);
  }

  public UploadAgentDirectDebit(documenthash: any): Observable<any> {
    return this.dataService.get('Activity/UploadAgentDirectDebit/' + documenthash);
  }
  // public UploadAgentDirectDebit(documenthash: any): Observable<any> {
  //   return this.dataService.get(this.addOnCRMBasePath + 'NewAgentInfirmation/UploadAgentDirectDebit/' + documenthash);
  // }

  // public SaveAgentDirectDebit(objSave: any) {
  //   return this.dataService.post(this.addOnCRMBasePath + 'NewAgentInfirmation/SaveAgentDirectDebit', objSave);
  // }


  public SaveAgentDirectDebit(objSave: any) {
    return this.dataService.post('Activity/DirectDebitSave', objSave);
  }

  public SaveNewAgentInfirmation(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'NewAgentInfirmation/SaveNewAgentInfirmation', objSave);
  }
  public SendDocumentNew(): Observable<any> {
    return this.dataService.get(`Activity/SendDocumentNew`);
  }


  public GetAllAgentHistory(agentcode: any, status: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentSignUp/GetAllAgentHistory/' + agentcode + '/' + status);
  }

  public sendAgentSignupResetEmail(agentSignupId: number, agentcode: number, email: any) {
    return this.dataService.post(this.addOnCRMBasePath + `AgentSignUp/SendAgentSignupResetEmail/${agentSignupId}/${agentcode}/${email}`, null);
  }
  public DeleteAgentSignUp(id: number) {
    return this.dataService.delete(this.addOnCRMBasePath + 'AgentSignUp/DeleteAgentSignUp/' + id
    );
  }
  public DeleteNewAgentInfirmation(id: number) {
    return this.dataService.delete(this.addOnCRMBasePath + 'NewAgentInfirmation/DeleteNewAgentInfirmationId/' + id
    );
  }

  public DeleteAgentDocumentStoreSignUp(id: number) {
    return this.dataService.delete(this.addOnCRMBasePath + 'AgentDocumentStoreSignUp/DeleteAgentDocumentStoreSignUp/' + id
    );
  }
  // Start UL
  public GetUltimateLegalConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentUltimateLegal/Agent/' + id);
  }

  public GetGroupUltimateLegalConfig(id: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentUltimateLegal/Group/' + id);
  }
  public SaveUltimateLegalConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentUltimateLegal',
      objSave
    );
  }

  public SaveProductConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentUltimateLegal/SaveProductConfig', objSave);
  }

  public GetAgentULConfiguration(agentId: number) {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentUltimateLegal/Agent/' + agentId);
  }
  public SaveUltimateLegalGroupConfig(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentUltimateLegal/group',
      objSave
    );
  }
  public UpdateULEnableDiable(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath +
      'AgentUltimateLegal/UpdateULEnableDiable',
      objSave
    );
  }

  public GetProductTypeName(referencingCompanyId: any): Observable<any> {
    return this.dataService
      .get(this.addOnCRMBasePath + 'AgentGroupProduct/GetProductTypeName/' + referencingCompanyId);
  }
  // End UL
  public GetActurisNotesDocument(agentId: any, acturisRefno: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentDocumentStore/GetActurisNotesDocument/' + agentId + '/' + acturisRefno);
  }

  public SaveAgentDirectLandlordRG(objSendData: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentDirectLandlordRG/AddUpdateAgentDirectLandlordRG', objSendData);
  }
  public GetDirectLandlordRG(agentId: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentDirectLandlordRG/' + agentId);
  }
  public GetDirectLandlordRGGroupId(agentGroupId: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentDirectLandlordRG/Group/' + agentGroupId);
  }

  public SaveAnnouncement(objSendData: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'Announcement/SaveAnnouncement', objSendData);
  }

  public GetAllAnnouncement(objData: any): Observable<any> {
    return this.dataService.post(this.addOnCRMBasePath + 'Announcement/GetAllAnnouncement', objData);
  }

  public AddActivityInvoiceDoc(data: any) {
    return this.dataService.post(this.addOnCRMBasePath + 'AgentDocumentStore/AddActivityNote', data);
  }

  //Finance API
  public AddAgentInvoiceDocumentStore(fileData: FormData) {
    return this.dataService.post(this.addOnFinancePath + 'AgentInvoiceDocumentStore/AddAgentInvoiceDocumentStore', fileData);
  }
  public GetUnAssignedUsers(companyId: number): Observable<any> {
    return this.dataService.get(this.addOnOperationBasePath + 'Users/unassign/' + companyId);
  }

  public GetAgentContactList(agentId: number): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + "AgentContact/ContactList/" + agentId);
  }


  public GetAllTitle(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + "getAgentTitle");
  }

  public GetContactType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + "GetContactType");
  }
  public GeAgentContactById(id: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + "AgentContact/" + id);
  }
  public DeleteContact(id: any): Observable<any> {
    return this.dataService.delete(this.addOnCRMBasePath + "AgentContact/Delete/" + id);
  }

  public GetCountryList(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'countries');
  }
  public GetCompanyType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'CompanyType');
  }
  public GetRegulator(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Regulator');
  }
  public GetParentAgentList(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetParentAgentList');
  }
  public GetAllSystemAgentUsers(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetAllSystemAgentUsers');
  }

  public GetAgentCommision(agentId: any): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetAgentCommissionList/' + agentId);
  }

  public InsertUpdatAgentContact(objSave: any): Observable<any> {
    if (objSave.AgentContactId !== null && objSave.AgentContactId > 0)
      return this.dataService.put(this.addOnCRMBasePath + 'AgentContact/' + objSave.AgentContactId, objSave);
    else
      return this.dataService.post(this.addOnCRMBasePath + 'AgentContact', objSave);

  }

}
export function checkFileType(files: any) {
  for (let index = 0; index < files.length; index++) {
    if (
      files[index].name.toLowerCase().split(".").pop() === "jpeg" ||
      files[index].name.toLowerCase().split(".").pop() === "jpg" ||
      files[index].name.toLowerCase().split(".").pop() === "png" ||
      files[index].name.toLowerCase().split(".").pop() === "gif" ||
      files[index].name.toLowerCase().split(".").pop() === "bmp" ||
      files[index].name.toLowerCase().split(".").pop() === "pdf" ||
      files[index].name.toLowerCase().split(".").pop() === "msg" ||
      files[index].name.toLowerCase().split(".").pop() === "doc" ||
      files[index].name.toLowerCase().split(".").pop() === "docx" ||
      files[index].name.toLowerCase().split(".").pop() === "xls" ||
      files[index].name.toLowerCase().split(".").pop() === "xlsx" ||
      files[index].name.toLowerCase().split(".").pop() === "pptx" ||
      files[index].name.toLowerCase().split(".").pop() === "ppt" ||
      files[index].name.toLowerCase().split(".").pop() === "pptm" ||
      files[index].name.toLowerCase().split(".").pop() === "potx" ||
      files[index].name.toLowerCase().split(".").pop() === "ppsx"
    ) {
      return true;
    }
  }
  return false;
}

export function checkFileTypeInvoice(files: any) {
  var filTypeExt = "xls,xlsx,csv";
  var filetype = filTypeExt.split(',');

  for (let index = 0; index < files.length; index++) {
    if (
      filetype.indexOf(files[index].name.toLowerCase().split('.').pop()) > -1

    ) {
      return true;
    }
  }
  return false;
}


export function checkFileSize(files: any, fileSize: any = 20000000) {
  let isFileSizeAllowed = false;
  for (let index = 0; index < files.length; index++) {
    if (files[index].size < fileSize) {
      isFileSizeAllowed = true;
      return isFileSizeAllowed;
    }
  }
  return isFileSizeAllowed;
}


export function getBoolean(value) {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
    case 'True':
    case 'Yes':
    case 'On':
      return true;
    case undefined:
      return false;
    case null:
      return false;
    case 'null':
      return false;
    case 'undefined':
      return false;
    case '':
      return false;
    default:
      return false;
  }
}

export function getNumber(value) {
  switch (value) {
    case 1:
    case '1':
      return 1;
    case 2:
    case '2':
      return 2;
    default:
      return 0;
  }
}
